import * as React from "react";
import { EbookPromptContextProvider } from "./contexts";

type Props = {
  children: React.ReactNode;
};

export default function RootWrapper({ children }: Props) {
  return <EbookPromptContextProvider>{children}</EbookPromptContextProvider>;
}
