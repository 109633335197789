import * as React from "react";

/**
 * Keep track of whether they've ever seen the ebook prompt during this visit,
 * so we don't show it on every homepage visit.
 */
const EbookPromptContext = React.createContext({
  shown: false,
  setShown: (value: boolean) => {},
});

type Props = {
  children: React.ReactNode;
};

export function EbookPromptContextProvider({ children }: Props) {
  const [shown, setShown] = React.useState<boolean>(false);
  return (
    <EbookPromptContext.Provider value={{ shown, setShown }}>
      {children}
    </EbookPromptContext.Provider>
  );
}

export function useEbookPromptContext() {
  return React.useContext(EbookPromptContext);
}
