// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-benefits-ai-vs-ocr-tsx": () => import("./../../../src/pages/benefits-ai-vs-ocr.tsx" /* webpackChunkName: "component---src-pages-benefits-ai-vs-ocr-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-docrouter-tsx": () => import("./../../../src/pages/docrouter.tsx" /* webpackChunkName: "component---src-pages-docrouter-tsx" */),
  "component---src-pages-document-processing-ebook-tsx": () => import("./../../../src/pages/document-processing-ebook.tsx" /* webpackChunkName: "component---src-pages-document-processing-ebook-tsx" */),
  "component---src-pages-forgot-tsx": () => import("./../../../src/pages/forgot.tsx" /* webpackChunkName: "component---src-pages-forgot-tsx" */),
  "component---src-pages-freight-forwarders-tsx": () => import("./../../../src/pages/freight-forwarders.tsx" /* webpackChunkName: "component---src-pages-freight-forwarders-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-demo-tsx": () => import("./../../../src/pages/live-demo.tsx" /* webpackChunkName: "component---src-pages-live-demo-tsx" */),
  "component---src-pages-login-freight-tsx": () => import("./../../../src/pages/login-freight.tsx" /* webpackChunkName: "component---src-pages-login-freight-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logistics-document-processing-tsx": () => import("./../../../src/pages/logistics-document-processing.tsx" /* webpackChunkName: "component---src-pages-logistics-document-processing-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-self-serve-tsx": () => import("./../../../src/pages/self-serve.tsx" /* webpackChunkName: "component---src-pages-self-serve-tsx" */),
  "component---src-pages-shipamax-vs-traditional-ocr-tsx": () => import("./../../../src/pages/shipamax-vs-traditional-ocr.tsx" /* webpackChunkName: "component---src-pages-shipamax-vs-traditional-ocr-tsx" */),
  "component---src-pages-technology-integrations-tsx": () => import("./../../../src/pages/technology-integrations.tsx" /* webpackChunkName: "component---src-pages-technology-integrations-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-try-now-tsx": () => import("./../../../src/pages/try-now.tsx" /* webpackChunkName: "component---src-pages-try-now-tsx" */),
  "component---src-pages-whitepapers-tsx": () => import("./../../../src/pages/whitepapers.tsx" /* webpackChunkName: "component---src-pages-whitepapers-tsx" */),
  "component---src-pages-why-shipamax-tsx": () => import("./../../../src/pages/why-shipamax.tsx" /* webpackChunkName: "component---src-pages-why-shipamax-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog-index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-gated-content-page-tsx": () => import("./../../../src/templates/gated-content-page.tsx" /* webpackChunkName: "component---src-templates-gated-content-page-tsx" */),
  "component---src-templates-integration-page-tsx": () => import("./../../../src/templates/integration-page.tsx" /* webpackChunkName: "component---src-templates-integration-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-use-case-tsx": () => import("./../../../src/templates/use-case.tsx" /* webpackChunkName: "component---src-templates-use-case-tsx" */)
}

