/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from "react";
import RootWrapper from "./src/RootWrapper";

export function wrapRootElement({ element }) {
  return <RootWrapper>{element}</RootWrapper>;
}

// HubSpot page view tracking integration
export function onRouteUpdate({ location }) {
  if (process.env.NODE_ENV !== "production" || !Array.isArray(window._hsq)) {
    return;
  }

  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // react-helmet is using requestAnimationFrame (https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299)
  const trackPageView = () => {
    const path = location ? location.pathname : undefined;
    if (path !== "/login") {
      // Tracking code here
    }
  };

  if (typeof window.requestAnimationFrame === "function") {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(trackPageView);
    });
  } else {
    // simulate 2 requestAnimationFrame calls
    setTimeout(trackPageView, 32);
  }
}
